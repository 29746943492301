import firebase from 'firebase';
import { logger } from './logger';

const log = logger('firebase');
const storage = firebase.storage();

export const uploadPic = async (path, base64) => {
  const res = await fetch(base64);
  const blob = await res.blob();
  return new Promise((resolve, reject) => {
    const task = storage.ref(path).put(blob);
    task.on('state_changed', (snap) => {
      var progress = (snap.bytesTransferred / snap.totalBytes) * 100;
      log('uploadPic#progress: ', progress);
      switch (snap.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          log('uploadPic#paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          log('uploadPic#running');
          break;
      }
    }, (e) => {
      reject(e);
    }, async () => {
      resolve(await task.snapshot.ref.getDownloadURL());
    });
  });
};
