<template lang="pug">
  v-container(
    fluid
  ).pa-0.ma-0
    v-row(
      no-gutters
      :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
      justify="center"
    ).mt-8
      v-col(
        cols="12"
        sm="12"
        md="12"
      ).mb-10
        v-card(outlined).my-2
          v-card-text.text-center
            v-row(justify="center")
              v-col(
                v-if="loading"
                cols="12" 
                sm="12" 
                md="3"
              )
                v-skeleton-loader(type="image")
              v-col(
                v-else
                cols="12" 
                sm="12" 
                md="3"
              )
                v-avatar(size="200").mb-3
                  v-img(
                    :src="picURL"
                    :alt="`${firstName}'s image`"
                  )
                br
                v-btn(
                  v-show="isSelf && !isEditing"
                  outlined 
                  small
                  @click.stop="isEditing = true"
                ) Edit Profile
                br(v-show="isSelf && !isEditing")
                v-btn(
                  v-show="isSelf && !isEditing"
                  outlined 
                  small
                  @click.stop="showPasswordDialog = true"
                ).mt-2 Change Password
                image-cropper(
                  v-if="isEditing"
                  ref="imageCropperRef"
                  :aspect-ratio="1"
                  @done="doneCrop"
                )
                  template(slot="action")
                    v-btn(
                      outlined 
                      small 
                      @click.stop="$refs.imageCropperRef.selectFile()"
                    ) Upload Picture
              v-col(
                v-if="loading"
                cols="12" 
                sm="12" 
                md="9"
              ).text-left
                v-skeleton-loader(
                  type="image"
                  height="50"
                )
                v-skeleton-loader(type="paragraph").my-4
                v-skeleton-loader(
                  v-for="n in 4"
                  type="text@1"
                  :key="n"
                ).my-4
              v-col(
                v-else
                cols="12" 
                sm="12" 
                md="9"
              ).text-left
                v-form(
                  v-model="valid"
                  v-if="isEditing" 
                  ref="formRef" 
                  lazy-validation
                )
                  v-row(:justify="$isMediumAndDown ? 'center' : undefined")
                    v-col(
                      cols="10"
                      sm="10"
                      md="3"
                    )
                      v-text-field(
                        v-model="details.name.firstName"
                        outlined
                        label="First Name*"
                        :rules="[v => !!v || 'First Name is required.']"
                      )
                    v-col(
                      cols="10"
                      sm="10"
                      md="3"
                    )
                      v-text-field(
                        v-model="details.name.middleName"
                        label="Middle Name"
                        outlined
                      )
                    v-col(
                      cols="10"
                      sm="10"
                      md="3"
                    )
                      v-text-field(
                        v-model="details.name.lastName"
                        outlined
                        label="Last Name*"
                        :rules="[v => !!v || 'Last Name is required.']"
                      )
                    v-col(
                      cols="10"
                      sm="10"
                      md="9"
                    )
                      v-textarea(
                        v-model="details.aboutMe"
                        outlined
                        auto-grow
                        label="About Me"
                        hide-details
                      )
                    v-col(
                      cols="10"
                      sm="10"
                      md="9"
                    )
                      v-text-field(
                        v-model="details.location"
                        outlined
                        label="Location"
                        hide-details
                      )
                    v-col(
                      cols="10"
                      sm="10"
                      md="9"
                    )
                      v-text-field(
                        v-model="details.occupation"
                        outlined
                        label="Occupation"
                        hide-details
                      )
                div(v-else)
                  p(
                    :class="$isMediumAndDown ? 'fontsize-24' : 'fontsize-32'"
                  ).font-weight-black.text-center.text-sm-center.text-md-left.black--text {{ benefactor | prettifyFirst }}
                  p.text-center.text-sm-center.text-md-left
                    v-tooltip(
                      v-if="!firebaseUser.emailVerified && $isMediumAndDown"
                      right
                    )
                      template(#activator="{ on }")
                        v-chip(
                          v-on="on"
                          color="error"
                          small
                          @click="resendEmailVerification"
                        ).mx-2 Unverified
                      small Click to resend verification email
                  p(v-if="isSelf") Email Address: #[b {{ $currentUser.email }}]
                    |
                    v-chip(
                      v-if="firebaseUser.emailVerified"
                      color="primary"
                      small
                    ).mx-2 Verified
                    v-tooltip(
                      v-else-if="!firebaseUser.emailVerified && !$isMediumAndDown"
                      right
                    )
                      template(#activator="{ on }")
                        v-chip(
                          v-on="on"
                          color="error"
                          small
                          @click="resendEmailVerification"
                        ).mx-2 Unverified
                      small Click to resend verification email
                  template.fontsize-16.text-center.text-sm-center.text-md-left
                    p Story: #[b {{ story }}]
                    p Location: #[b {{ location }}]
                    p About me: #[b {{ aboutMe }}]
                    p Occupation: #[b {{ occupation }}]
                    p Donations Made: #[b {{ donationCount }}]
          v-card-actions(v-if="isEditing")
            v-spacer
            v-btn(
              outlined
              x-large
              color="error"
              :disabled="loading"
              @click.stop="isEditing = false"
            ) Cancel
            v-btn(
              color="primary" 
              x-large
              :disabled="!valid"
              :loading="loading"
              @click.stop="updateAccount"
            ) Submit 
              span(v-if="newPicBase64") & Upload Photo
            v-spacer(v-if="$isMediumAndDown")

        v-card(outlined).my-2
          v-card-text.text-center
            v-row(justify="center")
              v-col(cols="12")
                p(:class="$isMediumAndDown ? 'fontsize-24' : 'fontsize-32'").black--text Scholars funded
                v-row(v-if="loading")
                  v-col(
                    v-for="(v, i) in 4" 
                    cols="12"
                    sm="4"
                    md="3"
                    :key="i"
                  )
                    v-card
                      v-skeleton-loader(type="image, list-item-three-line")
                beneficiary-list(
                  v-else-if="donationCount"
                  :number="donationCount"
                  :ids="beneficiaryIds"
                )
                span(v-else).font-italic No donations made yet.
          //- v-divider
          //- v-card-text
            //- TODO: add tabs here. 
            //- Tab 1 "My beneficiaries" - List of the students I funded
            //- Tab 2 "My Donations" - Summary of all donations, including name 
            //- of student, funded amount, and tip if any.
    v-dialog(
      v-if="showPasswordDialog"
      v-model="showPasswordDialog"
      width="400"
      @click:outside="cancelPassword"
    )
      v-card
        v-card-title.primary.white--text Change Password
        v-card-text.px-5.pt-5.pb-0
          v-form(
            v-model="passwordValid"
            ref="passwordRef"
            lazy-validation
          )
            v-row(
              justify="center"
              no-gutters
            )
              v-col(cols="10")
                v-text-field(
                  v-model="oldPassword"
                  label="Old password"
                  outlined
                  :rules="[ v => !!v || 'Password is required' ]"
                  :type="oldPasswordVisible ? 'text' : 'password'"
                  :append-icon="oldPasswordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :error="passwordErrorShow"
                  :error-messages="passwordErrorMessage"
                  @click:append="oldPasswordVisible =! oldPasswordVisible"
                )
              v-col(cols="10")
                v-text-field(
                  v-model="newPassword"
                  label="New password"
                  outlined
                  :rules="passwordRules"
                  :type="newPasswordVisible ? 'text' : 'password'"
                  :append-icon="newPasswordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="newPasswordVisible =! newPasswordVisible"
                )
              v-col(cols="10")
                v-text-field(
                  v-model="confirmPassword"
                  label="Confirm new password"
                  outlined
                  :rules="confirmPasswordRules"
                  :type="confirmPasswordVisible ? 'text' : 'password'"
                  :append-icon="confirmPasswordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  @click:append="confirmPasswordVisible =! confirmPasswordVisible"
                )
        v-card-actions
          v-spacer
          v-btn(
            depressed
            :disabled="loading"
            @click="cancelPassword"
          ) Cancel
          v-btn(
            color="primary"
            :loading="loading"
            @click="submitPassword"
          ) Confirm
</template>

<script>
import imageDefault from '@/assets/person-placeholder.png';
import BeneficiaryList from '../components/BeneficiaryList';
import ImageCropper from '../components/ImageCropper';
import { uploadPic } from '../utils/firebase';
import { format } from 'date-fns';
import { get } from 'lodash';

export default {
  props: {
    uid: String
  },
  components: {
    BeneficiaryList,
    ImageCropper
  },
  data () {
    return {
      valid: false,
      loading: false,
      benefactor: {},
      showDialog: false,
      name: {},
      picURL: imageDefault,
      newPicURL: '',
      newPicBase64: '',
      donations: [],
      details: { name: {} },
      isEditing: false,
      showPasswordDialog: false,
      passwordRules: [
        v => !!v || 'New password is required',
        v => (v && v.length >= 6) || 'Password must be at least 6 characters long'
      ],
      confirmPasswordRules: [
        v => !!v || 'Confirm password is required',
        v => (v === this.newPassword) || 'Passwords does not match'
      ],
      oldPassword: '',
      oldPasswordVisible: false,
      newPassword: '',
      newPasswordVisible: false,
      confirmPassword: '',
      confirmPasswordVisible: false,
      passwordValid: false,
      passwordErrorShow: false,
      passwordErrorMessage: ''
    };
  },
  computed: {
    isSelf () {
      if (!this.$currentUser) return false;
      return this.$route.params.uid === this.$currentUser.uid;
    },
    firstName () {
      const name = this.benefactor.name || {};
      return name.firstName || '';
    },
    joinDate () {
      return this.benefactor.createdAt && format(this.benefactor.createdAt, 'MMMM d, yyyy');
    },
    donationCount () {
      return this.donations.length || 0;
    },
    beneficiaryIds () {
      return (this.donations || []).map(donation => get(donation, 'beneficiary.id'));
    },
    story () {
      return `
        ${this.firstName} began supporting scholars since ${this.joinDate} and have supported
        ${this.donationCount} scholar${this.donationCount === 1 ? '' : 's'} since.
      `;
    },
    location () {
      return this.benefactor.location || 'Unpecified';
    },
    aboutMe () {
      return this.benefactor.aboutMe || '--';
    },
    occupation () {
      return this.benefactor.occupation || '--';
    },
    firebaseUser () {
      return this.$store.state.auth.firebaseUser || {};
    }
  },
  watch: {
    $route (to, from) {
      this.fetchBenefactor();
    }
  },
  methods: {
    async fetchBenefactor () {
      try {
        this.loading = true;
        this.benefactor = await this.$store.dispatch('benefactors/get', { uid: this.uid });
        this.details = this.benefactor;
        this.donations = await this.$store.dispatch('donation/getByParams', { 'createdBy.uid': this.uid }) || [];
        this.name = this.benefactor.name;
        this.picURL = this.benefactor.picURL || imageDefault;
      } catch (e) {
        if (e.code === 'benefactor/not-found') {
          this.$router.push('/notfound');
        }
        this.$enqueueSnack(e);
      } finally {
        this.loading = false;
      }
    },
    async updateAccount () {
      try {
        const valid = this.$refs.formRef.validate();
        if (!valid) throw new Error('Form is invalid');
        this.loading = true;
        const payload = {
          uid: this.benefactor.uid,
          ...this.details
        };
        if (this.newPicBase64) {
          const uid = this.benefactor.uid;
          const path = `mightyminds/accounts/${uid}/${uid}`;
          this.newPicURL = await uploadPic(path, this.newPicBase64);
        }
        if (this.newPicURL) {
          payload.picURL = this.newPicURL;
        }
        await this.$store.dispatch('accounts/updateAccount', payload);
        await this.fetchBenefactor();
        if (this.newPicURL) {
          await this.$store.dispatch('auth/loadCurrentUser', {
            uid: this.benefactor.uid,
            force: true
          });
        }
        this.isEditing = false;
        this.$enqueueSnack('Profile update successfully!');
      } catch (e) {
        this.$enqueueSnack(e);
      } finally {
        this.loading = false;
      }
    },
    doneCrop (newPic) {
      this.picURL = newPic;
      this.newPicBase64 = newPic;
    },
    async resendEmailVerification () {
      try {
        await this.$store.dispatch('auth/resendVerification');
        this.$enqueueSnack('Email verification sent successfully.');
      } catch (e) {
        this.$enqueueSnack(e);
      }
    },
    cancelPassword () {
      this.showPasswordDialog = false;
      this.oldPassword = '';
      this.oldPasswordVisible = false;
      this.newPassword = '';
      this.newPasswordVisible = false;
      this.confirmPassword = '';
      this.confirmPasswordVisible = false;
    },
    async submitPassword () {
      this.resetPasswordError();
      const valid = this.$refs.passwordRef.validate();
      if (!valid) return;
      try {
        this.loading = true;
        await this.$store.dispatch('auth/updatePassword', {
          oldPassword: this.oldPassword,
          newPassword: this.confirmPassword
        });
        this.cancelPassword();
        this.$enqueueSnack('You password is changed successfully.');
      } catch (e) {
        if (e.code === 'auth/wrong-password') {
          this.passwordErrorShow = true;
          this.passwordErrorMessage = 'Incorrect password';
          this.$enqueueSnack(new Error('Entered password is incorrect.'));
        } else {
          this.$enqueueSnack(e);
        }
      } finally {
        this.loading = false;
      }
    },
    resetPasswordError () {
      this.passwordErrorShow = false;
      this.passwordErrorMessage = '';
    }
  },
  async created () {
    await this.fetchBenefactor();
  },
  mounted () {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>
.inherit {
  height: inherit; 
  width: inherit;
}
.mb-100 {
  margin-bottom: 100px;
}
</style>